<template>
	<svg aria-hidden="true" class="svg-icon">
		<use :xlink:href="symbolId" />
	</svg>
</template>

<script setup lang="ts">
/*
	use
	<svg-icon name="hamburger" />
	hamburger --- @/icons/hamburger
*/
import { computed } from 'vue'
const props = defineProps({
	prefix: {
		type: String,
		default: 'icon'
	},
	name: {
		type: String,
		required: true
	}
})

const symbolId = computed(() => `#${props.prefix}-${props.name}`)
</script>

<style scope lang="scss">
.svg-icon {
	width: 1em;
	height: 1em;
	fill: currentColor;
}
</style>
