
       if (typeof window !== 'undefined') {
         function loadSvg() {
           var body = document.body;
           var svgDom = document.getElementById('__svg__icons__dom__');
           if(!svgDom) {
             svgDom = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
             svgDom.style.position = 'absolute';
             svgDom.style.width = '0';
             svgDom.style.height = '0';
             svgDom.id = '__svg__icons__dom__';
             svgDom.setAttribute('xmlns','http://www.w3.org/2000/svg');
             svgDom.setAttribute('xmlns:link','http://www.w3.org/1999/xlink');
           }
           svgDom.innerHTML = "<symbol class=\"icon\" viewBox=\"0 0 1024 1024\"  id=\"icon-arrowLeft\"><path d=\"M708.864 968.704a64 64 0 0 0 0-90.496l-363.2-363.2L708.096 152.64A64 64 0 0 0 617.6 62.08l-406.336 406.4a63.68 63.68 0 0 0-16.64 28.864 64 64 0 0 0 16 63.68l407.68 407.68a64 64 0 0 0 90.56 0z\" fill=\"#515151\" /></symbol>";
           body.insertBefore(svgDom, body.lastChild);
         }
         if(document.readyState === 'loading') {
           document.addEventListener('DOMContentLoaded', loadSvg);
         } else {
           loadSvg()
         }
      }
        
export default {}